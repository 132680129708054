<template>
  <div class="area">
    <span class="area-name" @click="showArea">{{
      selectArea.text.text | textLength
    }}</span>
    <van-popup
      v-model="show"
      :style="{ height: '90%' }"
      round
      position="bottom"
    >
      <!--      <div class="selectAutoGps" >-->
      <!--        <div >-->
      <!--          <span>当前：{{gpsAddress||"未定位"}}</span>-->
      <!--        </div>-->
      <!--        <div><van-button type="info" plain @click="selectAutoGps" size="small" icon="location">刷新</van-button></div>-->
      <!--      </div>-->
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        round
        active-color="#1989fa"
        :options="options"
        @change="addressChange"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { api } from '@/api/index'

export default {
  data() {
    return {
      show: false,
      selectArea: { text: '请选择', value: '', children: [] },
      gpsAddress: '',
      cascaderValue: '',
      levels1: [], //缓存市级
      levels2: [], //缓存县级
      levels3: [], //缓存乡级
      levels4: [], // 缓存村级
      options: [],
      isCityLevel: true,
      //级联选择自定义字段
      fieldNames: {
        text: 'name',
        value: 'longcode',
        children: 'children',
      },
    }
  },
  mounted() {
    if (this.$store.state.userAddress.length > 0) {
      try {
        this.selectArea.text =
          this.getLastItem(this.$store.state.userAddress) || '请选择'
        // console.log('this.selectArea1',this.selectArea)
        this.gpsAddress =
          this.$store.state.userAddressLocation.region ||
          this.$store.state.userAddressLocation.district
      } catch (Exception) {
        console.log(Exception)
      }
    } else {
      // this.showArea();
      this.selectArea = { text: '全省', value: '', children: [] }
    }
  },
  methods: {
    showArea() {
      this.show = true
      if (this.options.length < 1) {
        this.getCityList()
      }
    },
    //获取城市列表
    getCityList() {
      this.options[0] = {
        text: '全省',
        value: 0,
      }
      api.getCityList().then((res) => {
        // 判断是否是市级列表
        if (res[0].level === 3) {
          this.isCityLevel = true
        } else if (res[0].level === 4) {
          this.isCityLevel = false
        }
        res.map((ct) => {
          this.options.push({
            text: ct.name,
            value: ct.id,
            children: [],
          })
        })
      })
    },
    //获取子区域地点
    async getChildArea(level, id) {
      return new Promise((resolve, reject) => {
        api
          .getChildList({ id: id })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addressChange(value) {
      var levelIndex = this.isCityLevel ? 3 : 2
      // 如果是第4层不用请求子区域
      if (value.tabIndex === levelIndex) {
        return
      }
      if (value.value === 0) {
        // alert('请选择了全部');
        this.onFinish(value)
        return
      }
      this.getChildArea(value.tabIndex + 1, value.value).then((t) => {
        console.log(t, value)
        if (t.length > 0) {
          this['levels' + 1] = []
          const index = value.tabIndex + 1
          this['levels' + index] = []
          this['levels' + index][0] = {
            text: '全部',
            value: 0,
          }
          t.map((i) => {
            if (value.tabIndex < levelIndex - 1) {
              this['levels' + index].push({
                text: i.name,
                value: i.id,
                checkStrictly: true,
                children: [],
              })
            } else {
              this['levels' + index].push({
                text: i.name,
                value: i.id,
              })
            }
          })
          value.selectedOptions[value.tabIndex].children =
            this['levels' + index]
        } else {
          // 删除options数组中最后一组数据
          // value.selectedOptions[value.tabIndex].children = [{
          //   text: value.selectedOptions[value.tabIndex].text,
          //   value: ""
          // }];
          // Toast("暂无其他子区域");
        }
      })
    },
    //返回数组最后一项
    getLastItem(arr) {
      return arr[arr.length - 1]
    },
    onFinish({ selectedOptions }) {
      const _this = this
      _this.show = false
      // 如果最后一项text为全部，则删除最后一项
      console.log({ selectedOptions })
      if (selectedOptions.length > 0) {
        if (selectedOptions[selectedOptions.length - 1].value === 0) {
          selectedOptions.pop()
        }
      }
      console.log(selectedOptions)
      _this.$store.commit(
        'changeUserAddress',
        selectedOptions.length > 0 ? selectedOptions.map((i) => i.text) : [],
      )
      _this.$emit('change', selectedOptions)
      // selectArea = selectedOptions最后一项
      let showText = '全省'
      if (selectedOptions.length < 1) {
        showText = '全省'
      } else {
        showText = selectedOptions[selectedOptions.length - 1].text
      }
      console.log({ showText })
      _this.selectArea.text = showText
      console.log(_this.selectArea)
    },
    selectAutoGps() {
      ZWJSBridge.getLocation()
        .then((result) => {
          console.log(result)
          this.$store.commit('changeUserAddress', [result.city, result.region])
          this.$store.commit('changeUserAddressLocation', result)
          this.selectArea.text = this.getLastItem(this.$store.state.userAddress)
          console.log('this.selectArea2', this.selectArea)
          Toast('定位成功')
        })
        .catch((error) => {
          console.log(error)
          Toast('获取定位失败')
        })
      // this.setUserLocation();
      this.show = false
    },
  },
  filters: {
    // 过滤器
    // 文字长度过滤
    textLength(text) {
      if (text.length > 3) {
        return text.slice(0, 3) + '...'
      } else {
        return text
      }
    },
  },
}
</script>

<style lang="less" scoped>
.area {
  .area-name {
    position: relative;

    &::after {
      position: absolute;
      top: 12px;
      right: -10px;
      margin-top: -5px;
      border: 3px solid;
      border-color: transparent transparent #686b73 #686b73;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0.8;
      content: '';
    }
  }
}

.selectAutoGps {
  padding: 10px;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #666;
}

::v-deep .van-cascader__options {
  //border:1px solid red;
  height: calc(65vh);
}
</style>
