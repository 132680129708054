<template>
  <div class="header">
    <div class="left"><AreaPicker @change="addressChange" v-if="showAreaPicker" /></div>
    <div class="right">
      <div v-show="showOld" class="old-box" @click="showOldTap()">
        {{ isOld ? '标准版' : '长辈版' }}
      </div>
      <van-search
        v-show="showSearch"
        v-model="searchValue"
        show-action
        placeholder="请输入搜索关键词"
        @search="handlerSearch"
      >
        <!--        <template #label>-->
        <!--          <img-->
        <!--            src="../../assets/home/scan.png"-->
        <!--            class="scan"-->
        <!--            @click="handleScan"-->
        <!--          />-->
        <!--        </template>-->
        <template #action>
          <div @click="handlerSearch" style="color: #244e8a">搜索</div>
        </template>
      </van-search>
    </div>
    <div v-if="showExplain" @click="handleExplain">
      <img src="./../../assets/icon/icon-i.svg" />
      <div class="exBtn">
        {{ explainText }}
      </div>
    </div>
  </div>
</template>

<script>
import AreaPicker from '../areaPicker/index'
import { Toast } from 'vant'
export default {
  props: {
    //是否显示区域选择器
    showAreaPicker: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showOld: {
      type: Boolean,
      default: false,
    },
    //是否显示说明按钮
    showExplain: {
      type: Boolean,
      default: false,
    },
    // 扩展按钮文字
    explainText: {
      type: String,
      default: '',
    },
  },
  components: {
    AreaPicker,
  },
  data() {
    return {
      searchValue: '',
      isOld: this.$store.state.isOld,
    }
  },
  methods: {
    showOldTap() {
      if (this.isOld) {
        document.querySelector('html').style.fontSize = 37.5 + 'px'
      } else {
        document.querySelector('html').style.fontSize = 50 + 'px'
      }
      this.isOld = !this.isOld
      this.$store.commit('changeIsOld', this.isOld)
    },
    handleScan() {
      this.$toast('扫一扫')
      ZWJSBridge.scan({ type: 'qrCode' })
        .then((data) => {
          // data =>{"text" : "扫描到的内容"}
          Toast(data.text)
          // 判断是否为URL
          if (data.text.indexOf('http') > -1) {
            // 跳转到页面
            document.location.replace(data.text)
          } else {
            // 搜索
            Toast('不支持文本：' + data.text)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handlerSearch() {
      this.$emit('search', this.searchValue)
    },
    // 点击说明按钮
    handleExplain() {
      this.$emit('explain')
    },
    addressChange(address) {
      this.handlerSearch()
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .van-search {
  background-color: transparent;
}
.old-box {
  width: 90px;
  padding: 5px 0;
  background: #2c72f7;
  color: white;
  font-size: 13px;
  text-align: center;
  border-radius: 5px;
  float: right;
}
.header {
  display: flex;
  align-items: center;
  height: 44px;
  .left {
    width: 30%;
  }
  .right {
    width: 70%;
    .scan {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
  & > div:nth-child(3) {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #333333;
    > img {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .van-search__content {
  padding-left: 0;
}
</style>
